<template>
  <div class="home">
    <div class="cover" :style="{ marginBottom: coverMarginBottom }">
      <Header
        :title="$t('home.title')"
        :subtitle="$t('home.subtitle')"
        filled
        ref="header"
      >
        <div id="iphone" ref="iphone">
            <img src="@/assets/phone-screen-erik-3.jpg"/>
            <img src="@/assets/iphone.png"/>
        </div>
      </Header>

      <Section>
        <b-row>
          <b-col sm="9">

            <p style="margin-bottom: 0"> {{ $t('home.intro.text') }} </p>
<!--            <a href="#" v-scroll-to="'#EOF'"> {{ $t('home.intro.cta') }} </a>-->

          </b-col>
        </b-row>
      </Section>

      <Section>
        <h3> {{ $t('home.work.heading') }} </h3>

        <Shelf :items="$t('home.work.items')">
          <template v-slot:item="{ item }">
            <Card :link="item.link">
              <template #header>
                <Cover :aspectRatio="16/9" :assetUrl="item.coverImage"/>
              </template>
              <h5> {{ item.time }} </h5>
              <h4> {{ item.title }} </h4>
              <p v-html="item.text"></p>
            </Card>
          </template>
        </Shelf>
      </Section>

      <Section>
        <h3> {{ $t('home.services.heading') }} </h3>

        <b-row>
          <b-col xs="4" sm="4" lg="4">
            <Icon
              icon="bubbling-flask"
              height="4rem"
            />
            <h4> {{ $t('home.services.development.heading') }} </h4>
            <p> {{ $t('home.services.development.text') }} </p>
          </b-col>
          <b-col xs="4" sm="4" lg="4">
            <Icon
              icon="pencil"
              height="4rem"
              style="padding-top: calc(0.4rem * 4/2.9)"
            />
            <h4> {{ $t('home.services.design.heading') }} </h4>
            <p> {{ $t('home.services.design.text') }} </p>
          </b-col>
          <b-col xs="4" sm="4" lg="4">
            <Icon
              icon="chat-bubble"
              height="4rem"
              style="padding-top: calc(1.0rem * 4/2.9)"
            />
            <h4> {{ $t('home.services.consulting.heading') }} </h4>
            <p> {{ $t('home.services.consulting.text') }} </p>
          </b-col>
        </b-row>
      </Section>
      <span id="EOF" :style="{ position: 'absolute', bottom: '-' + coverMarginBottom }"></span>
    </div>
    <Footer @heightChanged="coverMarginBottom = $event + 'px'"/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Section from '@/components/Section.vue';
import Shelf from '@/components/Shelf.vue';
import Card from '@/components/Card.vue';
import Icon from '@/components/Icon.vue';
import Cover from '@/components/Cover.vue';
import Footer from '@/components/Footer.vue';
import parallax from '../parallax';

export default {
  name: 'home',
  components: {
    Header,
    Section,
    Shelf,
    Card,
    Icon,
    Cover,
    Footer,
  },
  data: () => ({
    coverMarginBottom: '0px',
  }),
  mounted() {
    parallax.setup(this.$refs.header);
    parallax.add(this.$refs.iphone, offset => Math.max(offset, 0) * 0.4);
    parallax.add(this.$refs.header.$refs.text, offset => offset * 0.2);

    this.$refs.iphone.classList.add('slidesInUp');
    this.$refs.header.$refs.text.classList.add('slidesInUp');
  },
};
</script>

<style scoped lang="scss">

.cover {
  background-color: white;
  position: relative;
  z-index: 1;
}

#iphone {
  visibility: hidden;
  position: absolute;
  height: 24rem;
  top: 4rem;

  right: calc(50% - 12rem);

  @include media-breakpoint-down(sm) {
    top: 17rem;
  }

  @each $breakpoint in md, lg, xl {
    @include media-breakpoint-up($breakpoint) {
      $out: map-get($container-max-widths, $breakpoint);
      right: calc((100vw - #{$out})/2);
    }
  }

  img {
    position: absolute;
    right: 0;
    max-height: 24rem;
    width: calc(800/813 * 24rem);

    &:first-child {
        //filter: grayscale(70%);

        border-top-left-radius: 4rem;
        border-top-right-radius: 4rem;
        padding: 1rem;
    }

    //&:last-child {
    //  filter: drop-shadow(-1rem 1rem 2.8rem rgba(black, .5));
    //
    //  @include media-breakpoint-down(sm) {
    //    filter: drop-shadow(0 1rem 2.8rem rgba(black, .5));;
    //  }
    //}
  }
}

</style>
