import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    meta: require('./copy/en/meta'), // eslint-disable-line global-require
    footer: {
      heading: 'Let’s get in touch',
      phoneNumberLabel: 'Phone number',
      emailLabel: 'Email',
      linkedinLabel: 'LinkedIn',
      chamberOfCommerceLabel: 'Chamber of commerce no.',
    },
    home: {
      title: 'Erik van der Plas',
      subtitle: 'Software Engineer',
      intro: {
        text: `
          As a software engineer I have experience building apps for a variety of platforms,
          websites and machine learning applications. Most importantly, I am able to solve complex
          problems through creative use of technology.
          I use my skills to help companies realize their most ambitious ideas digitally.
        `,
        cta: 'Contact me to have me help you.',
      },
      work: {
        heading: 'My experience',
        items: require('./copy/en/workItems'), // eslint-disable-line global-require
      },
      services: {
        heading: 'What can I do for you?',
        ...require('./copy/en/services'), // eslint-disable-line global-require
      },
    },
    notFound: require('./copy/en/notFound'), // eslint-disable-line global-require
  },

  nl: {
    meta: require('./copy/nl/meta'), // eslint-disable-line global-require
    footer: {
      heading: 'We houden contact',
      phoneNumberLabel: 'Telefoonnummer',
      emailLabel: 'Email',
      linkedinLabel: 'LinkedIn',
      chamberOfCommerceLabel: 'KvK',
    },
    home: {
      title: 'Erik van der Plas',
      subtitle: 'Software Engineer',
      intro: {
        text: `
          Als software engineer heb ik ervaring met het bouwen van apps voor verschillende
          platforms, websites en machine learning toepassingen. Bovenal ben ik in staat om
          complexe problemen op te lossen door creatief gebruik te maken van technologie. Hiermee help
          ik bedrijven om hun meest ambitieuze ideeën digitaal te verwezenlijken.
        `,
        cta: 'Neem contact op.',
      },
      work: {
        heading: 'Mijn ervaring',
        items: require('./copy/nl/workItems'), // eslint-disable-line global-require
      },
      services: {
        heading: 'Wat kan ik voor jou doen?',
        ...require('./copy/nl/services'), // eslint-disable-line global-require
      },
    },
    notFound: require('./copy/nl/notFound'), // eslint-disable-line global-require
  },
};

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
