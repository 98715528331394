module.exports = {
  home: {
    title: 'Erik van der Plas - Software Engineer',
    description: `
      As a software engineer I have experience building apps for a variety of platforms,
      websites and machine learning applications. Most importantly, I am able to solve complex
      problems through creative use of technology.
      I use my skills to help companies realize their most ambitious ideas digitally.
    `,
    keywords: [
      'app development',
      'erik van der plas',
      'eric van der plas',
      'developer',
      'designer',
      'amsterdam',
      'design',
      'haarlem',
      'T500',
      'wwdc',
      'scholarship',
      'ondernemer',
      'website',
      'websites',
      'websitebouwer',
      'appbouwer',
      'software',
      'engineer',
    ],
  },
  notFound: {
    title: '404 - Not found',
    description: 'This page does not exist!',
  },
};
