import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import i18n from './i18n';

Vue.use(Router);

const localizedMeta = (i18nKey, additional) => (() => {
  const metaBase = i18n.t(i18nKey);
  return {
    title: metaBase.title,
    metaTags: [
      {
        name: 'description',
        content: metaBase.description,
      },
      {
        name: 'keywords',
        content: (metaBase.keywords || []).join(', '),
      },
      ...(metaBase.tags || []).concat(additional || []),
    ],
  };
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      thumb: 'phone-screen-erik-3.jpg',
      component: Home,
      meta: localizedMeta('meta.home', [
        {
          property: 'og:image',
          content: 'https://www.erikvanderplas.com/media/phone-screen-erik-3.jpg',
        },
      ]),
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    //   meta: defaultMeta(
    //     'About me',
    //     'TESTTT',
    //     ['test', 'testss'],
    //     [],
    //   ),
    // },
    {
      path: '*',
      name: '404',
      component: () => import('./views/NotFound.vue'),
      meta: localizedMeta('meta.notFound'),
    },
  ],
});
