module.exports = {
  title: '404: Not found',
  subtitle: 'Likely this isn’t what you were looking for.',
  intro: {
    text: `
      The page you requested does not exist (anymore). Try visiting my
      <a href="/?lang=en">homepage</a> or pick one of the suggestions below.`,
  },
  suggestions: {
    heading: 'Suggestions',
  },
};
