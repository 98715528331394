module.exports = {
  title: '404: Niet gevonden',
  subtitle: 'Hier was je waarschijnlijk niet naar op zoek.',
  intro: {
    text: `
      De pagina die je verzocht bestaat niet (meer). Bezoek anders eens
      mijn <a href="/?lang=nl">homepage</a> of kies een van de suggesties
      hieronder.`,
  },
  suggestions: {
    heading: 'Suggesties',
  },
};
