<template>
  <b-row>
    <div class="shelf" :style="hasTouch ? { 'scroll-snap-type': 'x mandatory' } : {}" ref="wrapper">
      <b-col
        v-for="item in items"
        v-bind:key="item.id"
        xs="6" sm="6" lg="4"
        class="item"
      >
        <slot name="item" :item="item"></slot>
      </b-col>
    </div>
  </b-row>
</template>

<script>
import Impetus from 'impetus';

const isTouchDevice = () => {
  const prefixes = ['-webkit-', '-moz-', '-o-', '-ms-'];

  // eslint-disable-next-line no-undef
  if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return window.matchMedia(query).matches;
};

export default {
  name: 'Shelf',
  data() {
    return {
      previousX: 0,
      impetus: null,
      hasTouch: false,
    };
  },
  props: {
    items: Array,
  },
  methods: {
    scrollBy(delta) {
      this.$refs.wrapper.scrollBy({ left: delta });
    },
  },
  mounted() {
    if (!isTouchDevice()) {
      this.impetus = new Impetus({
        source: this.$refs.wrapper,
        update: (x) => {
          const delta = x - this.previousX;
          this.scrollBy(-delta);
          this.previousX = x;
        },
      });
    } else {
      this.hasTouch = true;
    }
  },
};
</script>

<style scoped lang="scss">
.shelf {
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  /*scroll-snap-type: x mandatory;*/

  // Vertical margin and padding allows for up to 7rem of overflow
  /*
    TODO: Vertical margin is chosen to have scrollbar on iOS in hidden overflow
    of encompassing section, but this isn't guaranteed (i.e. other placements
    than as the final element in the section would still show the scrollbar,
    albeit very low.
  */
  margin: -7rem calc((100vw - 100%) / -2);
  padding: 7rem calc((100vw - 100%) / 2);
  width: 100vw;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  @each $breakpoint in sm, md {
    @include media-breakpoint-up($breakpoint) {
      $out: map-get($container-max-widths, $breakpoint);
      scroll-padding: 0 0 0 calc(
        (100vw - #{$out}) / 2 - #{$grid-gutter-width / 4}
      );
    }
  }

  @include media-breakpoint-up(lg) {
    scroll-padding: 0;
  }

  .item {
    scroll-snap-align: center;
    display: inline-block;
    float: none;
    vertical-align: top;
    height: 100%;
    white-space: normal;
    user-select: none;

    @include media-breakpoint-down(md) {
      scroll-snap-align: start;
    }

    @include media-breakpoint-down(xs) {
      scroll-snap-align: center;
      width: 90%;
      padding-left: $grid-gutter-width / 3;
      padding-right: $grid-gutter-width / 3;

      &:first-child {
        margin-left: $grid-gutter-width / 6;
      }

      &:last-child {
        margin-right: $grid-gutter-width / 6;
      }
    }
  }
}
</style>
