<template>
  <div class="icon-wrap" :style="{ width, height }">
    <component :is="pascalCaseIcon"></component>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: String,
    width: String,
    height: String,
  },
  components: {
    BubblingFlask: () => import('@/components/icons/BubblingFlask.vue'),
    ChatBubble: () => import('@/components/icons/ChatBubble.vue'),
    Pencil: () => import('@/components/icons/Pencil.vue'),
  },
  computed: {
    pascalCaseIcon() {
      return this.icon.match(/[a-z]+/gi)
        .map(word => word.charAt(0).toUpperCase()
            + word.substr(1).toLowerCase())
        .join('');
    },
  },
};
</script>

<style lang="scss">
.icon-wrap {
  color: white;
  margin-bottom: 1rem;
  display: inline-block;

  > * {
    height: 100%;
    width: auto;
  }
}

.darkest-fill {
  fill: $darkest-color;
}

.darkest-stroke {
  stroke: $darkest-color;
}
</style>
