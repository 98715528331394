module.exports = {
  development: {
    heading: 'Development',
    text: `
      Ik heb tientallen apps ontwikkeld voor o.a. iOS en web.
      Zowel solo als in teamverband heb ik ervaring met het bouwen
      van kwaliteitssoftware.
    `,
  },
  design: {
    heading: 'Design',
    text: `
      Design moet uitleggen hoe je product werkt en gebruikers loyaal
      houden. Met mijn frisse blik op technologie kan ik jouw product laten
      uitblinken.
    `,
  },
  consulting: {
    heading: 'Consulting',
    text: `
      Technologie staat verre van stil.\n
      Ik ben op de hoogte van state-of-the-art technologieën, zoals AI,
      die je producten rijker kunnen maken.
    `,
  },
};
