module.exports = {
  home: {
    title: 'Erik van der Plas - Software Engineer',
    description: `Als software engineer heb ik ervaring met het bouwen van apps voor verschillende
          platforms, websites en machine learning toepassingen. Bovenal ben ik in staat om
          complexe problemen op te lossen door creatief gebruik te maken van technologie. Hiermee help
          ik bedrijven om hun meest ambitieuze ideeën digitaal te verwezenlijken.`,
    keywords: [
      'app development',
      'erik van der plas',
      'eric van der plas',
      'developer',
      'designer',
      'amsterdam',
      'design',
      'haarlem',
      'T500',
      'wwdc',
      'scholarship',
      'ondernemer',
      'website',
      'websites',
      'websitebouwer',
      'appbouwer',
      'software',
      'engineer',
    ],
  },
  notFound: {
    title: '404 - Niet gevonden',
    description: 'Deze pagina bestaat niet!',
  },
};
