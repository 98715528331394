<template>
  <div :style="{
    paddingBottom: `calc(${1 / this.aspectRatio} * 100%)`,
    width: '100%',
    ...backgroundStyle,
  }"></div>
</template>

<script>
export default {
  name: 'Cover',
  props: {
    aspectRatio: Number,
    assetUrl: String,
  },
  computed: {
    backgroundStyle() {
      if (this.assetUrl) {
        return {
          // eslint-disable-next-line
          backgroundImage: `url(${require(`@/assets/${this.assetUrl}`)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        };
      }

      return {
        backgroundColor: '#D0D0D0',
      };
    },
  },
};
</script>
