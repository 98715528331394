module.exports = [
  {
    time: 'Sep 2020 - Nu',
    title: 'GoSpooky',
    text: `
        Bij GoSpooky stond ik aan de wieg van het machine learning team.
        We ontwikkelden o.a. TrackAR: een computer vision model om
        3D objecten in Snapchat te tracken (<a style="font-size: 1em !important;" href="https://www.linkedin.com/posts/liamtjoa_really-love-the-innovation-we-have-done-on-ugcPost-6895030491939241984-lbKZ"
      target="_blank">video</a>).
    `,
    link: 'https://gospooky.com/',
    coverImage: 'cover-gospooky.jpg',
  },
  {
    time: 'Nov 2019 - Aug 2020',
    title: 'Manpower',
    text: `
       Bij een van de grootste uitzendbureau’s van Nederland, Manpower,
       gaf ik advies over de toepassing van AI en data science om
       dagelijkse processen effectiever te maken.
    `,
    link: 'https://www.manpower.nl',
    coverImage: 'cover-manpower.jpg',
  },
  {
    time: 'Sep 2019 - Nu',
    title: 'Universiteit Utrecht',
    text: `
        Ik volg momenteel de BSc. Kunstmatige Intelligentie en de BSc. Wiskunde.
        Mijn academische interesses zijn met name machine learning en wiskundig modelleren.
    `,
    coverImage: 'cover-uu.jpg',
  },
  {
    time: 'Oct 2019',
    title: 'Woonwaard energie:spaar:app',
    text: `
      Voor woningcorporatie Woonwaard bouwde ik een app die
      huurders beloont voor energiebesparingen. Via spaaracties
      zijn zo duizenden groene doelen voltooid.
    `,
    link: 'https://www.woonwaard.nl/voor-huurders/geldzaken/energie-spaar-app',
    coverImage: 'cover-woonwaard.jpg',
  },
  {
    time: 'Jun 2018 - Sep 2019',
    title: 'Speaky',
    text: `
      Speaky maakt intelligente voice experiences. Als developer bij
      Speaky werkte ik aan conversational apps en
      nieuwe technologieën voor de voice interface.
    `,
    link: 'https://www.emerce.nl/wire/gospookyoprichters-starten-speaky-nieuwe-agency-gericht-voice',
    coverImage: 'cover-speaky.jpg',
  },
  {
    time: 'Jan 2017 - Dec 2018',
    title: 'Kurator',
    text: `
      Kurator was een app waarmee locals de beste gecureerde plekken en
      events in hun stad kunnen vinden. Als co-founder bouwde ik zowel de iOS en Android app als de back-end.
    `,
    link: 'https://getkurator.com/',
    coverImage: 'cover-kurator.jpg',
  },
  {
    time: 'Jun 2014 - Jun 2017',
    title: 'WWDC Scholarships',
    text: `
      Apple's WWDC Scholarships belonen
      getalenteerde studenten met een bezoek aan hun jaarlijkse
      conferentie. Ik heb vier scholarships gewonnen: in 2014, 2015, 2016 en
      2017.
    `,
    link: 'https://developer.apple.com/wwdc/',
    coverImage: 'cover-wwdc.jpg',
  },
  {
    time: 'Nov 2016 - Okt 2018',
    title: 'Kinder',
    text: `
      Kinder is bedacht om de chaotische wereld van doneren te verbeteren door
      goed doen toegankelijker te maken. Ik was verantwoordelijk voor de
      Kinder iOS app.
    `,
    link: 'https://kinder.world',
    coverImage: 'cover-kinder.jpg',
  },
  {
    time: 'Jan 2017 - Apr 2018',
    title: 'Time',
    text: `
      We ontwikkelden deze app om duizenden mensen te helpen hun
      deadlines te halen. Door de slimme interface was de app een effectiever
      dan tranditionele todo-apps.
    `,
    link: 'https://www.macstories.net/reviews/time-review-powered-by-ai-and-you/',
    coverImage: 'cover-time.jpg',
  },
  {
    time: 'Apr 2017 - Jun 2018',
    title: 'Young Advisory Board',
    text: `
      De YAB is een unieke groep jongeren tussen de 16 en 24 jaar, die
      bedrijven helpt groter te worden en te innoveren. Ik droeg bij
      met mijn kennis over innovatieve technologiëen.
    `,
    link: 'https://www.linkedin.com/company/young-advisory-board-yab/',
    coverImage: 'cover-yab.jpg',
  },
  // {
  //   time: 'Sep 2016',
  //   title: 'Machine Learning certificaat verworven',
  //   text: `
  //     Ik heb succesvol (97.3% goed) een online course over Machine Learning van
  //     Stanford University afgerond. Ik breid mijn kennis over ML verder uit
  //     door publicaties te lezen.
  //   `,
  //   link: 'https://www.coursera.org/account/accomplishments/verify/6BTNVPTAPW8Q',
  //   coverImage: 'cover-machine-learning.jpg',
  // },
  // {
  //   time: 'Okt 2016',
  //   title: 'Moby Instant',
  //   text: `
  //     Een product van Mobypicture: deel avonturen met vrienden op alle sociale
  //     media. Moby Instant maakt het mogelijk om te delen vanaf je iPhone,
  //     vanuit de app of de handige share extensie.
  //   `,
  //   link: 'https://itunes.apple.com/us/app/moby-instant/id641533240?mt=8',
  //   coverImage: 'cover-moby-instant.jpg',
  // },
  // {
  //   time: 'Jul 2016',
  //   title: 'Remix',
  //   text: `
  //     Remix is een tool voor de content management stack van Tag the
  //     Love om onderweg content te verzamelen. Remix content op je iPhone, en
  //     breng het samen in je eigen blogs.
  //   `,
  //   link: 'https://itunes.apple.com/us/app/remix/id1117499499',
  //   coverImage: 'cover-remix.jpg',
  // },
  {
    time: 'Jul 2015 - Aug 2015',
    title: 'The Next Web',
    text: `
      In de zomer van 2015 liep ik stage bij TNW, een online media bedrijf dat
      inzicht biedt in de wereld van technologie. Ik werkte aan een app voor
      dochterbedrijf Index.co.
    `,
    coverImage: 'cover-tnw.jpg',
  },
  // {
  //   time: 'Nov 2014 - Aug 2015',
  //   title: 'KeyTouch',
  //   text: `
  //     <a style="font-size: 1em !important;" href="http://www.tomderuiter.com/"
  //     target="_blank">Tom de Ruiter</a> en ik hebben een app gebouwd die het
  //     mogelijk maakte je Mac met Touch ID op de iPhone te ontgrendelen: veilig,
  //     snel en gemakkelijk.
  //   `,
  //   coverImage: 'cover-keytouch.jpg',
  // },
  {
    time: '2011 - Nu',
    title: 'Overige projecten',
    text: `
      Ik heb nog aan een hoop andere uiteenlopende projecten gewerkt!
      Verder heb ik, voordat ik in opdracht ging werken, nog een aantal eigen, kleine apps gebouwd.
    `,
    coverImage: 'cover-more.jpg',
  },
];
