
const prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' ');
const testEl = document.createElement('div');
const cssTransform = prefixes.find(p => testEl.style[p] !== undefined);

const ieFallback = c => window.setTimeout(c, 1000 / 60);
const requestAnimationFrame = [
  window.requestAnimationFrame,
  window.mozRequestAnimationFrame,
  window.webkitRequestAnimationFrame,
  window.msRequestAnimationFrame,
  window.oRequestAnimationFrame,
  ieFallback,
].find(v => v !== null && v !== undefined);

export default {
  currentScroll: 0,
  update() {
    this.updates.forEach(({ el, map }) => {
      // eslint-disable-next-line
      el.style[cssTransform] = `translate3d(0,${map(this.currentScroll)}px,0)`;
    });
  },
  loop() {
    const boundingRect = this.container.getBoundingClientRect();
    const minY = boundingRect.top + window.scrollY;
    const maxY = boundingRect.bottom + window.scrollY;
    const height = maxY - minY;

    const scroll = window.scrollY - minY;
    if (this.currentScroll === scroll) {
      requestAnimationFrame(this.loop.bind(this));
      return;
    }

    const oldScroll = this.currentScroll;
    this.currentScroll = scroll;

    if (this.currentScroll >= 0 && this.currentScroll <= height) {
      this.update();
    } else if (this.currentScroll < 0 && oldScroll >= 0) {
      const realScroll = this.currentScroll;
      this.currentScroll = 0;
      this.update();
      this.currentScroll = realScroll;
    } else if (this.currentScroll > height && oldScroll < height) {
      const realScroll = this.currentScroll;
      this.currentScroll = height;
      this.update();
      this.currentScroll = realScroll;
    }

    requestAnimationFrame(this.loop.bind(this));
  },
  setup(container) {
    this.updates = [];
    this.container = container.$el || container;
    this.loop();
  },
  add(el, map) {
    this.updates.push({
      el: el.$el || el,
      map: map || (v => v),
    });
  },
};
