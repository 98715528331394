import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueScrollTo);

router.beforeEach((to, from, next) => {
  i18n.locale = ((to.query.lang || window.navigator.language) || 'en').slice(0, 2);

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta().title);

  if (!nearestWithTitle) {
    return next();
  }

  const title = nearestWithTitle.meta().title || document.title;
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta().metaTags);
  const metaTags = nearestWithMeta.meta().metaTags || [];

  document.title = title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]'))
    .map(el => el.parentNode.removeChild(el));

  let generatedMetaTags = [
    {
      name: 'dcterms.title',
      content: title,
    },
    {
      name: 'dcterms.rights',
      content: `${new Date().getFullYear()} Erik van der Plas`,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:url',
      content: `https://www.erikvanderplas.com${nearestWithTitle.path}`,
    },
  ];

  const description = (metaTags.find(t => t.name === 'description') || {}).content;

  if (description) {
    generatedMetaTags = generatedMetaTags.concat([
      {
        name: 'dcterms.description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      },
    ]);
  }

  const allMetaTags = [...generatedMetaTags, ...metaTags];

  allMetaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  return next();
});

const gaID = 'UA-139564767-1';
router.afterEach((to) => {
  if (process.env.NODE_ENV !== 'development') {
    gtag('config', gaID, { page_path: to.path, anonymize_ip: true });
  }
});

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
