module.exports = {
  development: {
    heading: 'Development',
    text: `
      I\xa0have built dozens of apps for iOS, web, and other platforms.
      Both solo as well as in teams, I\xa0have experience building quality software.
    `,
  },
  design: {
    heading: 'Design',
    text: `
      Design must explain how your product works and keep customers loyal.
      With my fresh perspective at technology, I\xa0can make your product
      stand out.
    `,
  },
  consulting: {
    heading: 'Consulting',
    text: `
      Technology is always expanding into new fields.
      I know all about modern technologies, like AI,
      that can make your products more meaningful.
    `,
  },
};
