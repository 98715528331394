<template>
  <div class="footer-wrap" ref="wrap">
    <footer ref="heightBox">
      <b-container>
        <h3> {{ $t('footer.heading') }} </h3>

        <b-row class="contact-options">
          <b-col sm="6" lg="3">
            <h5> {{ $t('footer.phoneNumberLabel') }} </h5>
            <a href="tel:+31616945480"> +31 6 16945480 </a>
          </b-col>
          <b-col sm="6" lg="3">
            <h5> {{ $t('footer.emailLabel') }} </h5>
            <a href="mailto:hi@erikvanderplas.com"> hi@erikvanderplas.com </a>
          </b-col>
          <b-col sm="6" lg="3">
            <h5> {{ $t('footer.linkedinLabel') }} </h5>
            <a href="https://www.linkedin.com/in/erik-van-der-plas/" target="_blank"> Erik van der Plas </a>
          </b-col>
          <b-col sm="6" lg="3">
            <h5> {{ $t('footer.chamberOfCommerceLabel') }} </h5>
            <a> 68624123 </a>
          </b-col>
        </b-row>

        <br />
        <h5>&copy; {{ new Date().getFullYear() }} Erik van der Plas</h5>
      </b-container>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    subtitle: String,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowWidthChanged);
      window.addEventListener('scroll', this.onScroll);
      this.windowWidthChanged();
    });
  },
  methods: {
    windowWidthChanged() {
      const footerHeight = this.$refs.heightBox.clientHeight;
      this.$emit('heightChanged', footerHeight);
    },
    onScroll() {
      if (document.body.scrollTop < 0) {
        this.$refs.wrap.classList.add('footer-wrap-obscured');
      } else {
        this.$refs.wrap.classList.remove('footer-wrap-obscured');
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowWidthChanged);
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style scoped lang="scss">
.footer-wrap {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.footer-wrap-obscured {
  visibility: hidden;
}

footer {
  font-size: 1.1rem;

  padding: 6rem 0;
  color: white;

  * {
    color: white;
  }

  h5 {
    color: $highlight-color;
  }

  .contact-options > * {
    margin-bottom: 2rem;
  }
}
</style>
