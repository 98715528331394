<template>
  <div :class="{ 'header-wrap': true, 'filled': filled }">
    <header>
      <b-container ref="text" id="text">
        <h1 v-html="title"></h1>
        <h2 v-html="subtitle"></h2>
      </b-container>
    </header>
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    subtitle: String,
    filled: Boolean,
    colorRotation: {
      default: '0',
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrap {
  background: $noise, white;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  header {
    padding: 9rem 0;
    background-color: rgba($highlight-color, .5);
    color: white;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 6em 0 6em 0;
    }

    #text {
      visibility: hidden;
    }
  }

  &.filled {
    background: radial-gradient(
      circle at 50% 65%,
      rgba(black, .3) 0%,
      rgba(black, .0) 50%,
    ), $noise, white;

    @include media-breakpoint-up(md) {
      background: radial-gradient(
        circle at 60% 50%,
        rgba(black, .3) 0%,
        rgba(black, .0) 50%,
      ), $noise, white;
    }

    @include media-breakpoint-up(lg) {
      background: radial-gradient(
        circle at 70% 50%,
        rgba(black, .3) 0%,
        rgba(black, .0) 50%,
      ), $noise, white;
    }

    header {
      @include media-breakpoint-down(sm) {
        padding: 6em 0 24em 0;
      }
    }
  }
}
</style>
