module.exports = [
  {
    time: 'Sep 2020 - Today',
    title: 'GoSpooky',
    text: `
        At GoSpooky I helped setting up the machine learning team.
        In particular, we developed TrackAR: a computer vision model
        to track 3D objects inside of Snapchat (<a style="font-size: 1em !important;" href="https://www.linkedin.com/posts/liamtjoa_really-love-the-innovation-we-have-done-on-ugcPost-6895030491939241984-lbKZ"
      target="_blank">video</a>).
    `,
    link: 'https://gospooky.com/',
    coverImage: 'cover-gospooky.jpg',
  },
  {
    time: 'Nov 2019 - Aug 2020',
    title: 'Manpower',
    text: `
       For the Dutch branch of the world’s third-largest staffing firm, Manpower,
       I gave advice on the application of AI and data science to make
       their daily operations more effective.
    `,
    link: 'https://www.manpower.nl',
    coverImage: 'cover-manpower.jpg',
  },
  {
    time: 'Sep 2019 - Today',
    title: 'University of Utrecht',
    text: `
        I\xa0am currently enrolled in the BSc. Artificial Intelligence and the BSc. Mathematics.
        My academic interests are primarily machine learning and mathematical modelling.
    `,
    coverImage: 'cover-uu.jpg',
  },
  {
    time: 'Oct 2019',
    title: 'Woonwaard energy:savings:app',
    text: `
      For housing association Woonwaard I built a mobile app that
      rewards users for efforts to save energy. Motivated by
      green prizes, renters completed 1000+ planet-friendly actions.
    `,
    link: 'https://www.woonwaard.nl/voor-huurders/geldzaken/energie-spaar-app',
    coverImage: 'cover-woonwaard.jpg',
  },
  {
    time: 'Jun 2018 - Sep 2019',
    title: 'Speaky',
    text: `
      Speaky creates intelligent voice experiences. As a developer at
      Speaky I\xa0worked on conversational apps and
      exciting new technologies for the voice interface.
    `,
    link: 'https://www.emerce.nl/wire/gospookyoprichters-starten-speaky-nieuwe-agency-gericht-voice',
    coverImage: 'cover-speaky.jpg',
  },
  {
    time: 'Jan 2017 - Dec 2018',
    title: 'Kurator',
    text: `
      Kurator was an app that let locals discover the best curated
      spots and events in their city.
      As a founding partner, I built both the iOS and Android app as well as the back end.
    `,
    link: 'https://getkurator.com/',
    coverImage: 'cover-kurator.jpg',
  },
  {
    time: 'Jun 2014 - Jun 2017',
    title: 'WWDC Scholarships',
    text: `
      Apple's WWDC Scholarships reward talented
      students with the opportunity to attend the annual conference. I won four
      scholarships: in 2014, 2015, 2016 and 2017.
    `,
    link: 'https://developer.apple.com/wwdc/',
    coverImage: 'cover-wwdc.jpg',
  },
  {
    time: 'Nov 2016 - Oct 2018',
    title: 'Kinder',
    text: `
      Kinder was founded to disrupt the broken world of donating by making
      doing good more accessible. I was responsible for the
      development of the Kinder iOS app.
    `,
    link: 'https://www.joinkinder.com',
    coverImage: 'cover-kinder.jpg',
  },
  {
    time: 'Jan 2017 - Apr 2018',
    title: 'Time',
    text: `
      Time was made to help people beat procrastination and get ahead of
      deadlines, using a remarkably simple interface, and has proven to do so
      for thousands of happy customers.
    `,
    link: 'https://techcrunch.com/2017/01/09/times-ai-powered-time-tracking-app-will-help-you-stop-procrastinating',
    coverImage: 'cover-time.jpg',
  },
  {
    time: 'Apr 2017 - Jun 2018',
    title: 'Young Advisory Board',
    text: `
      The YAB is a unique group of people ranging from 16 to 24 years of
      age, helping companies grow and innovate. I contributed with my
      knowledge about innovative technologies.
    `,
    link: 'https://www.linkedin.com/company/young-advisory-board-yab/',
    coverImage: 'cover-yab.jpg',
  },
  // {
  //   time: 'Sep 2016',
  //   title: 'Earned Machine Learning Certificate',
  //   text: `
  //     I successfully (graded 97.3%) finished an online course on Machine
  //     Learning by Stanford University. Afterwards I continued to expand my ML
  //     knowledge by reading research papers.
  //   `,
  //   link: 'https://www.coursera.org/account/accomplishments/verify/6BTNVPTAPW8Q',
  //   coverImage: 'cover-machine-learning.jpg',
  // },
  // {
  //   time: 'Oct 2016',
  //   title: 'Moby Instant',
  //   text: `
  //     A Mobypicture product: share adventures with friends on all your social
  //     networks. Moby Instant allows you to share right from your iPhone, from
  //     the app or with the nifty share extension.
  //   `,
  //   link: 'https://itunes.apple.com/us/app/moby-instant/id641533240?mt=8',
  //   coverImage: 'cover-moby-instant.jpg',
  // },
  // {
  //   time: 'Jul 2016',
  //   title: 'Remix',
  //   text: `
  //     Remix is a tool for the the ultimate content management stack by Tag the
  //     Love to curate content on the go. Remix content on your iPhone, and put
  //     everything together in Create to build beautiful blogs.
  //   `,
  //   link: 'https://itunes.apple.com/us/app/remix/id1117499499',
  //   coverImage: 'cover-remix.jpg',
  // },
  {
    time: 'Jul 2015 - Aug 2015',
    title: 'The Next Web',
    text: `
      In the summer of 2015 I interned at TNW, an online media company that
      gives insight to the world of technology. I worked on an app for its subsidiary company Index.co.
    `,
    coverImage: 'cover-tnw.jpg',
  },
  // {
  //   time: 'Nov 2014 - Aug 2015',
  //   title: 'KeyTouch – Connecting Touch ID',
  //   text: `
  //     <a style="font-size: 1em !important;" href="http://www.tomderuiter.com/"
  //     target="_blank">Tom de Ruiter</a> and I built an app that enabled people
  //     to unlock their Mac with Touch ID on their iPhone, to make security quick
  //     and easy. The app's purpose became obsolete however; official support has
  //     been discontinued.
  //   `,
  //   coverImage: 'cover-keytouch.jpg',
  // },
  {
    time: '2011 - Today',
    title: 'Other projects',
    text: `
      I have also worked on plenty of other, diverse projects!
      Furthermore, before I started doing contract work, I built and distributed a few small-scale apps on my own.
    `,
    coverImage: 'cover-more.jpg',
  },
];
