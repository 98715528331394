<template>
<div :class="{ 'nb-card': true, 'clickable': hasValidLink }"
     @mousedown="onMouseDown"
     @mousemove="onMouseMove"
     @mouseup="onMouseUp">
  <div class="nb-card-header">
    <slot name="header"></slot>
  </div>

  <div class="nb-card-content">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'Card',
  data() {
    return {
      mouseStartX: null,
      mouseMoved: false,
    };
  },
  props: {
    link: String,
    replace: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onMouseDown(event) {
      this.mouseStartX = event.clientX;
      this.mouseMoved = false;
    },
    onMouseMove(event) {
      if (Math.abs(event.clientX - this.mouseStartX) > 1) {
        this.mouseMoved = true;
      }
    },
    onMouseUp(event) {
      if (!this.mouseMoved && event.target.nodeName !== 'A') {
        this.openLink();
      }
    },
    openLink() {
      if (this.hasValidLink) {
        if (this.link.charAt(0) === '#') {
          const id = this.link.substr(1);
          document.getElementById(id).scrollIntoView();
        } else {
          window.open(this.link, this.replace ? '_self' : undefined);
        }
      }
    },
  },
  computed: {
    hasValidLink() {
      return this.link && this.link !== '';
    },
  },
};
</script>

<style scoped lang="scss">
.nb-card {
  height: 100%;
  background-color: white;
  border-radius: .4rem;
  box-shadow: $default-box-shadow;
  border: $default-border;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
    transition: box-shadow .2s, transform .2s;

    &:hover {
      transform: scale(1.018);
      box-shadow: $elevated-box-shadow;
    }
  }

  .nb-card-header {

  }

  .nb-card-content {
    padding: 1.5rem 1rem 1rem 1rem;
  }


  font-size: 1rem;

  p, a {
    font-size: 1rem;
  }
}
</style>
